@use "../../app/styles/common/function";


.root {
  position: absolute;
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.loading {
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  @media (max-width: 768px) {
    width: function.calcSize(20, 60, 360, 768);
    height: function.calcSize(20, 60, 360, 768);
  }
}

.logo {
  width: 365px;
  height: 108px;
  object-fit: cover;
  width: function.calcSize(254, 365, 768, 1920);
  height: function.calcSize(78, 108, 768, 1920);
  @media (max-width: 768px) {
    width: function.calcSize(147, 254, 360, 768);
    height: function.calcSize(43, 78, 360, 768);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
