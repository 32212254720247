@use "../../../app/styles/common/function";

.wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20px;
  z-index: 300;
  row-gap: 10px;
  right: function.calcSize(0, 20, 360, 1920);
  
  .root {
    border-radius: 12px;
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(116, 120, 129, 0.25);
    box-shadow: 0px 1px 10px 0px rgba(176, 182, 194, 0.2);
    z-index: 100;
    position: relative;
    animation: commigCard 1s 0s 1;
    max-width: function.calcSize(361, 490, 360, 1920);
    width: function.calcSize(361, 490, 360, 1920);
    column-gap: 10px;
    align-items: flex-start;
    padding-block: function.calcSize(12, 16, 360, 1920);
    padding-inline: function.calcSize(12, 20, 360, 1920);
    box-shadow: 0px 0px 20px 0px rgba(116, 120, 129, 0.25);
    box-shadow: 0px 1px 10px 0px rgba(176, 182, 194, 0.2);
    @media (max-width: 362px) {
      max-width: 100%;
    }

    &:hover {
      // background-color: #28a746;
      cursor: pointer;
    }

    .content {
      color: rgba(10, 12, 19, 1);
      font-family: Comfortaa;
      font-weight: 500;
      font-size: 20px;
      line-height: 22.3px;
      text-align: left;
      max-width: function.calcSize(263, 370, 360, 1920);
      min-width: function.calcSize(263, 370, 360, 1920);
      @media (max-width: 360px) {
        max-width: function.calcSize(200, 263, 200, 360);
        min-width: function.calcSize(200, 263, 200, 360);
      }
      @media (max-width: 362px) {
        min-width: 100%;
      }
    }

    .notificationTitle {
      margin: 0;
      font-family: Comfortaa;
      font-weight: 500;
      font-size: 20px;
      line-height: 22.3px;
      font-size: function.calcSize(16, 20, 360, 1920);
    }

    .notificationText {
      font-family: Comfortaa;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      font-size: function.calcSize(12, 16, 360, 1920);
      line-height: function.calcSize(17, 18, 360, 1920);
      color: rgba(136, 136, 136, 1);
    }

    .svg {
      display: flex;
      align-items: center;
      height: function.calcSize(40, 46, 360, 1920);
      width: function.calcSize(40, 46, 360, 1920);
      @media (max-width: 362px) {
        display: none;
      }
    }

    .successContainer {
      padding-right: 50px;
      width: 40px;
      height: 40px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 15px;
        height: 5px;
        top: 20px;
        left: 0px;
        background-color: white;
        rotate: (45deg);
        border-radius: 20px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 25px;
        height: 5px;
        top: 15px;
        left: 7px;
        background-color: white;
        rotate: (-45deg);
        border-radius: 20px;
      }
    }

    .errorContainer {
      padding-right: 50px;
      width: 40px;
      height: 40px;
      position: relative;
      &::before {
        position: absolute;
        content: "!";
        width: 15px;
        height: 5px;
        left: 10px;
        color: white;
        font-weight: 600;
        font-size: 2.7rem;

        border-radius: 20px;
      }
    }
  }

  .error {
    background-color: #dc3546d9;
    &:hover {
      background-color: #dc3546;
    }
  }
}

@keyframes commigCard {
  0% {
    right: -600px;
  }
  100% {
    right: 0px;
  }
}
.closeSvg {
  background-color: rgba(255, 255, 255, 0);
  @media (max-width: 362px) {
    display: none;
  }
}
