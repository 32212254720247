@use "../../../app/styles/common/function";

.header {
  display: flex;
  align-items: center;
  width: 100dvw;
  max-width: 100%;
  position: relative;
  top: 0;
  left: 0;
  height: function.calcSize(60, 84, 360, 1920);
}
.blurContainer {
background: rgba(255, 255, 255, 0.60);
box-shadow: 81px 353px 101px 0px rgba(176, 182, 194, 0.00), 52px 226px 93px 0px rgba(176, 182, 194, 0.01), 29px 127px 78px 0px rgba(176, 182, 194, 0.05), 13px 56px 58px 0px rgba(176, 182, 194, 0.09), 3px 14px 32px 0px rgba(176, 182, 194, 0.10);
backdrop-filter: blur(10px);



max-width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: function.calcSize(60, 84, 360, 1920);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoImg {
  width: function.calcSize(58, 93, 360, 1920);
  height: function.calcSize(40, 64, 360, 1920);
  cursor: pointer;
  object-fit: cover;
  z-index: 10;
}

.userImg {
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(176, 182, 194, 0.20), 0px 0px 20px 0px rgba(116, 120, 129, 0.25);
  width: function.calcSize(42, 60, 360, 1920);
  height: function.calcSize(42, 60, 360, 1920);

  display: flex;

padding: 10px;
padding: function.calcSize(8, 10, 360, 1920);
justify-content: center;
align-items: center;
z-index: 1;
}
