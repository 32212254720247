@use "../../../app/styles/common/var";

.root {
  min-height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {

  @media (max-width: 500px) {
    flex: 1;
  }
}
.backgroundMain {
position: absolute;
top: 0px;
left: 0;
z-index: -1;
object-fit: cover;
height: 100dvh;
min-height: 100%;
width: 100dvw;
overflow: hidden;
@media (max-width: 500px) {
  display: none;
}
}
.imgWrapperMobile {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: -1;
}
.backgroundMainMobile {
  display: none;
  @media (max-width: 500px) {
    // display: inline;
    // position: absolute;
    // top: 120px;
    // left: 0;
    // z-index: -1;
    // object-fit: cover;
    // // height: 100dvh;
    // width: 100%;
    // min-height: 100%;
    // // overflow: hidden;





    display: inline;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: -1;
    // object-fit: cover;
    // height: 100%;
    // width: 100%;
    max-height: 100%;
    max-width: 100%;
    min-height: 812px;
    min-width: 100%;
}
}
