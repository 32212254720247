@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/PoiretOne-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../../public/fonts/Inter-Bold.ttf') format('woff2');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  src: url('../../../public/fonts/Comfortaa-Light.ttf') format('woff2');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  src: url('../../../public/fonts/Comfortaa-Medium.ttf') format('woff2');
}
