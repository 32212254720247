@forward "common/libs";
@forward "common/fonts/fonts";
@use "common/function";

:root {
  color-scheme: light;
  --color-red-text: #9D0003;
  --color-text-opasity: #747881;
  --color-text-gray: #747881;
  --color-bg-success: #26C656;
  --color-bg-error: #BD3133;
  --color-bg-warn: #007BCE;
  --color-text-disabled: #7A7D7F;
  --font-size-title: 36px;
}
html {
  scroll-behavior: smooth;
}
body {
  color: black;

  &::-webkit-scrollbar {
    height: 0px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
  }

}

.dark {
  color-scheme: dark;
  --color-red-text: #ffffff;
}
