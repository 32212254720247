@use "../../../app/styles/common/function";

.root {
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1600px) {
    width: 100%;
    padding-inline: 25px;
    padding-inline: function.calcSize(16, 45, 360, 1550);
  }
}
.container {
  width: 1480px;
}
