@use "../../../app/styles/common/function";

.footer {
  height: function.calcSize(70, 80, 360, 1920);
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

}

.filter {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.60);
  box-shadow: 81px 353px 101px 0px rgba(176, 182, 194, 0.00), 52px 226px 93px 0px rgba(176, 182, 194, 0.01), 29px 127px 78px 0px rgba(176, 182, 194, 0.05), 13px 56px 58px 0px rgba(176, 182, 194, 0.09), 3px 14px 32px 0px rgba(176, 182, 194, 0.10);
  backdrop-filter: blur(10px);
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 410px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.leftSide {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.emailSvg {
  height: function.calcSize(20, 28, 360, 1920);
  width: function.calcSize(20, 36, 360, 1920);
}
.phoneSvg {
  height: function.calcSize(20, 32, 360, 1920);
  width: function.calcSize(20, 32, 360, 1920);
}

.rightSide {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.footerItemText {
  font-family: Poiret One;
  font-weight: 400;
  line-height: 39.78px;

  color: rgba(0, 0, 0, 1);
  font-size: function.calcSize(16, 34, 360, 1920);
}
